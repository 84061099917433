import React, { useState, ReactNode, Fragment } from 'react';
import './Tooltip.css';

interface TooltipProps {
	children: ReactNode;
	content: ReactNode;
	alignment?: 'left' | 'right' | 'top' | 'bottom' | 'center';
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, alignment = 'right' }) => {
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

	const handleMouseEnter = () => {
		setIsTooltipVisible(true);
	};

	const handleMouseLeave = () => {
		setIsTooltipVisible(false);
	};

	const getAlignmentClasses = () => {
		switch (alignment) {
			case 'center':
				return 'origin-left left-0 transform -translate-x-full';
			case 'left':
				return 'origin-left -left-32 -translate-x-full';
			case 'right':
				return 'origin-right right-0 transform translate-x-full';
			case 'top':
				return 'origin-top top-0 transform -translate-y-full';
			case 'bottom':
				return 'origin-bottom bottom-0 transform translate-y-full';
			default:
				return 'origin-top top-0 transform -translate-y-full';
		}
	};

	return (
		<span
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
			{isTooltipVisible && (
				<div className='absolute inline-block'>
					<div
						className={`tooltip-overlay ${getAlignmentClasses()}`}
						style={{
							position: 'absolute',
							zIndex: 9999,
						}}
					>
						<div
							className="z-10 tooltip-content bg-gray-900 text-white rounded-lg shadow-sm tooltip dark:bg-gray-700"
							role="tooltip"
						>
							{content}
							<div className="tooltip-arrow" data-popper-arrow></div>
						</div>
					</div>
				</div>
			)}
		</span>
	);
};

export default Tooltip;