import { ReactNode } from 'react';
import TwoSidebar from '../organisms/TwoSidebar';

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="flex">
            <TwoSidebar />

            <div style={{ width: "100%" }}>
                {children}
            </div>
        </div>
    );
};

export default Layout;