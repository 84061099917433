import React from 'react';
import JSONViewer from 'react-json-view';

function JsonViewer(jsonData: any) {

  return (
      <JSONViewer src={jsonData}  displayDataTypes={false}/>
  );
}

export default JsonViewer;