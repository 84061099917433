import { Suspense, lazy } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import App from "./App";
import NotFound from "./pages/errors/NotFound";
import AccessDenied from "./pages/errors/AccessDenied";
import InternalError from "./pages/errors/InternalError";
import SolidarityPartnersPage from "./pages/partners/SolidarityPartners";
import Logout from "./pages/Logout";

import NetworkError from "./pages/errors/NetworkError";
import PartnerFormPage from "./pages/partners/PartnerForm";
import PartnerViewPage from "./pages/partners/PartnerView";
import PartnerListPage from "./pages/partners/PartnerList";

import ScoreAgrolendModal from "./pages/referrals/ScoreAgrolendModal";
import Loading from "./ions/Loading";
import BiroListModal from "./pages/referrals/BiroListModal";
import BiroVariablesModal from "./pages/referrals/BiroVariablesModal";
import BiroDocumentModal from "./pages/referrals/BiroDocumentModal";
import ReferralDetailModal from "./pages/referrals/ReferralDetailModal";

import ReferralPartnerAnalysisListPage from './pages/referrals/PartnerAnalysisList';
import ReferralPartnerAnalyzedListPage from './pages/referrals/PartnerAnalyzedList';
import RequireAuth from "./molecules/Auth/RequireAuth";

const Cities = lazy(() => import('./pages/geo-location/Cities'));
const Provinces = lazy(() => import('./pages/geo-location/Provinces'));

// const ReferralPartnerAnalysisListPage = lazy(() => import('./pages/referrals/PartnerAnalysisList'));
// const ReferralPartnerAnalyzedListPage = lazy(() => import('./pages/referrals/PartnerAnalyzedList'));

const AppRoutes = () => {

    const ADMIN = 'ADMIN';
    const OPERATOR = 'OPERATOR';
    const LEGAL = 'LEGAL';
    const CREDIT_ANALYST = 'CREDIT_ANALYST';
    const CREDIT_MANAGER = 'CREDIT_MANAGER';


    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<App />} errorElement={<InternalError />}>
                    <Route path="home" element={<RequireAuth roles={[ADMIN, OPERATOR, LEGAL, CREDIT_ANALYST, CREDIT_MANAGER]}><Home /></RequireAuth>} />
                    <Route path="partners" element={<PartnerListPage />} />
                    <Route path="partners/view/:id" element={<PartnerViewPage />} />
                    <Route path="partners/form" element={<PartnerFormPage />} />
                    <Route path="solidarity-partners" element={<SolidarityPartnersPage />} />
                    <Route path="referrals/partner-analysis/:page/:pageSize/:field/:order/:filter?"
                        element={<RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}><ReferralPartnerAnalysisListPage /></RequireAuth>}>
                        <Route
                            path="agrolend-score/:referralId"
                            element={<ScoreAgrolendModal />}
                        />
                        <Route
                            path="biro-list/:referralId"
                            element={<BiroListModal />}
                        />
                        <Route
                            path="biro-variables/:referralId/:providerName"
                            element={<BiroVariablesModal />}
                        />
                        <Route
                            path="biro-document/:referralId/:providerName"
                            element={<BiroDocumentModal />}
                        />
                        <Route
                            path="referral-detail/:referralId"
                            element={<ReferralDetailModal />}
                        />
                    </Route>
                    <Route path="referrals/partner-analyzed/:page/:pageSize/:field/:order/:filter?"
                        element={<RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}><ReferralPartnerAnalyzedListPage /></RequireAuth>}>
                        <Route
                            path="agrolend-score/:referralId"
                            element={<ScoreAgrolendModal />}
                        />
                        <Route
                            path="biro-list/:referralId"
                            element={<BiroListModal />}
                        />
                        <Route
                            path="biro-variables/:referralId/:providerName"
                            element={<BiroVariablesModal />}
                        />
                        <Route
                            path="biro-document/:referralId/:providerName"
                            element={<BiroDocumentModal />}
                        />
                        <Route
                            path="referral-detail/:referralId"
                            element={<ReferralDetailModal />}
                        />
                    </Route>
                    <Route path="cities" element={
                        <RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}>
                            <Suspense fallback={<Loading />}>
                                <Cities />
                            </Suspense>
                        </RequireAuth>
                    }
                    />
                    <Route path="provinces" element={
                        <RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}>
                            <Suspense fallback={<Loading />}>
                                <Provinces />
                            </Suspense>
                        </RequireAuth>}
                    />
                    <Route path="/denied" element={<AccessDenied />} />
                    <Route path="/error" element={<InternalError />} />
                    <Route path="/network-error" element={<NetworkError />} />
                    <Route path="/logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<NotFound />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </HashRouter>
    );
};

export default AppRoutes;