import React, { ReactElement, createElement, useState } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

type TabProps = {
    title: string,
    icon?: IconType,
    index: number,
    disabled?: boolean,
}

const Tab: React.FC<{ tabItems: TabProps[]; tabIndex: number;}> = ({ tabItems, tabIndex }) => {

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul id='tab' 
        data-tabs-toggle="#tab-content"
        role='tab-list'
        className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {tabItems.map((tabItem, index) => (
          <TabItem
            key={index}
            title={tabItem.title}
            icon={tabItem.icon}
            disabled={tabItem.disabled}
            index={tabItem.index}
            activeIndex={tabIndex}
          />
        ))}
      </ul>
    </div>
  );
};

type TabItemProps = {
    title: string;
    icon?: IconType;
    disabled?: boolean;
    index: number;
    activeIndex?: number;
  }

const TabItem: React.FC<TabItemProps> = ({ title, icon, index, activeIndex, disabled }) => {
    const tabClasses = `inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-blue-600 hover:border-blue-600 dark:hover:text-blue-600 dark:hover:border-blue-600 group ${
        index === activeIndex ? 'border-blue-600 text-blue-600' : 'border-transparent'
      } ${disabled ? 'cursor-not-allowed text-gray-400 dark:text-gray-500' : ''}`;
    
  let iconElement: ReactElement | null = null;
  if (icon) {
    iconElement = createElement(icon);
  }

  return (
    <li className="mr-2">
      {disabled ? (
        <Link to="#" className={tabClasses}>{title}</Link>
      ) : (
        <Link to={`?tab=${index}`} 
            className={tabClasses}
            aria-current={activeIndex === index ? 'page' : undefined}>
          {iconElement}
          {title}
        </Link>
      )}
    </li>
  );
};

export default Tab;