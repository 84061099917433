import { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import amplifyConfig from './amplify-config';

import Layout from './templates/Layout';
import LoginForm from './pages/Login';
// import './App.css';

Amplify.configure(amplifyConfig);

function App() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default App;