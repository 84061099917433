import FloatingActionButton from "../atoms/FloatingActionButton";
import { BiHelpCircle, BiAbacus, BiAddToQueue } from "react-icons/bi";
import MultiFloatingButton from "../molecules/MultiFloatingButton";

function Home() {

	const applicationName = "Backoffice";
	const version = "v1.0.0.0";

	const actionButtons = [
		{
			color: 'blue',
			icon: BiHelpCircle,
			hint: 'Action 1',
			onClick: () => {
				// Define the action to perform when Action 1 is clicked
			},
		},
		{
			color: 'lime',
			icon: BiAbacus,
			hint: 'Action 2',
			onClick: () => {
				// Define the action to perform when Action 2 is clicked
			},
		},
		// Add more action buttons as needed
	];

	return (
		<div className="h-full p-3">
			<h1 className="text-2xl">{applicationName}</h1>
			<span className="text-sm">{version}</span>
			<input type="hidden" id="cyan" className="bg-cyan-400 hover:bg-cyan-600" />
			<input type="hidden" id="neutral" className="bg-neutral-400 hover:bg-neutral-600" />
			<input type="hidden" id="red" className="bg-red-400 hover:bg-red-600" />
			<input type="hidden" id="lime" className="bg-lime-400 hover:bg-lime-600" />
			<input type="hidden" id="violet" className="bg-violet-400 hover:bg-violet-600" />
			<input type="hidden" id="blue" className="bg-blue-600 hover:bg-blue-800" />

			{/* <FloatingActionButton 
                hint="Add" 
                icon={BiHelpCircle}
                color="violet" 
                onClick={() => {
                    window.alert(`${applicationName} ${version}`);
            }} /> */}

			{/* <MultiFloatingButton
                mainButtonColor="red"
                mainButtonIcon={BiAddToQueue}
                mainButtonHint="Main Button Hint"
                actionButtons={actionButtons}
            /> */}

		</div>
	);
}

export default Home;