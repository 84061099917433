import { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";

const useFetch = (url: string) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [data, setData] = useState<any | null>(null);
    const [error, setError] = useState(null);
    const token = useRef('');

    useEffect(() => {

        setIsLoading(true);
        Auth.currentSession()
            .then((user) => user.getAccessToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchData(token))
            .catch((error) => {
                setIsTokenError(true);
                setIsLoading(false);
                setError(error);
            });

    }, [url]);

    const fetchData = async (token: string) => {

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        })
            .then(response => response.json())
            .then(data => {
                setData(data);
                setIsLoading(false);
            }).catch((error) => {
                setError(error);
                setIsLoading(false);
            })
    }

    return { isLoading, data, error, isTokenError };
}

export default useFetch;